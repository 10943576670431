import { forwardRef } from 'react';
import { CircularProgress } from '../CircularProgress';
import styles from './styles.module.css';

type ButtonSize = 'small' | 'medium' | 'large';
type ButtonColor = 'blue' | 'red';
type ButtonVariant = 'solid' | 'outline' | 'ghost';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: ButtonSize;
  color?: ButtonColor;
  variant?: ButtonVariant;
  loading?: boolean;
};

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      size = 'medium',
      color = 'blue',
      variant = 'solid',
      loading,
      disabled,
      children,
      ...rest
    },
    ref
  ) => {
    const classNames = `${styles.button} ${styles[size]} ${styles[color]} ${styles[variant]}`;

    return (
      <button
        ref={ref}
        className={classNames}
        disabled={disabled || loading}
        {...rest}
      >
        {loading ? <CircularProgress size="small" /> : children}
      </button>
    );
  }
);

Button.displayName = 'Button';

export { Button };
